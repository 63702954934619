var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "result",
    { attrs: { isSuccess: true, content: false, title: _vm.email } },
    [
      _c(
        "template",
        { slot: "action" },
        [
          _c(
            "a-button",
            {
              staticStyle: { "margin-left": "8px" },
              attrs: { size: "large" },
              on: { click: _vm.goHomeHandle },
            },
            [_vm._v("返回首页")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }